<template>
  <div>
    <div
      v-if="image"
      class="p-3 text-left rounded-lg h-horizontal-product mdmax:h-horizontal-product-mobile bg-no-repeat bg-cover"
      :class="{ 'border border-grey-field': withBorder }"
      :style="{ 'background-image': 'linear-gradient(63.43deg, rgba(51, 51, 51, 0.8) 33.33%, rgba(51, 51, 51, 0) 100%),url(' + image + ')' }"
    >
      <slot name="description"></slot>
    </div>
    <div v-else class="animate-pulse bg-neutral-300 text-left rounded-lg h-horizontal-product mdmax:h-horizontal-product-mobile"></div>
  </div>
</template>
<script>
export default {
  props: {
    withBorder: {
      type: Boolean,
      default: false
    },
    image: {
      type: String,
      default: ''
    }
  }
}
</script>
