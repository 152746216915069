<template>
  <figure v-lazyload class="image__wrapper">
    <img class="image__item" :data-url="source" :alt="alternateText" :class="imageClass" />
  </figure>
</template>

<script>
export default {
  name: 'ImageItem',
  props: {
    source: {
      type: String,
      required: true
    },
    imageClass: {
      type: String
    },
    alternateText: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
.image {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &.loaded {
      .image {
        &__item {
          visibility: visible;
          opacity: 1;
          border: 0;
        }
      }
    }
  }

  &__item {
    transition: all 0.4s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
