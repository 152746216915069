<template>
  <div class="mx-1 mt-1 shadow-small rounded-lg px-4 pb-6 mb-1">
    <div class="flex items-center">
      <div class="">
        <slot name="image"></slot>
      </div>
      <div class="text-left"><slot name="profile"></slot></div>
    </div>
    <div class="text-left -mt-3" :class="descriptionClass">
      <div class="text-6xl text-yellow">“</div>
      <slot name="description"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    descriptionClass: {
      type: String,
      default: ''
    }
  }
}
</script>
